var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-2",
                      attrs: { title: "User Management" },
                    },
                    [
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c("oem-api-table", {
                                ref: "table",
                                attrs: {
                                  action: "admin/getUsers",
                                  fields: _vm.fields,
                                  addModal: "admin-add-user",
                                },
                                on: { input: _vm.editUser },
                                model: {
                                  value: _vm.selected_user,
                                  callback: function ($$v) {
                                    _vm.selected_user = $$v
                                  },
                                  expression: "selected_user",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { id: "admin-add-user", title: "New User" },
          on: { ok: _vm.register },
        },
        [
          _vm.register_data.status
            ? _c(
                "b-alert",
                {
                  attrs: {
                    variant: _vm.register_data.status_variant,
                    show: "",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.register_data.status) + " ")]
              )
            : _vm._e(),
          _c(
            "b-form",
            { ref: "register_form" },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("name_label") + "*" } },
                [
                  _c("b-input", {
                    attrs: { type: "text", state: _vm.register_errors.name },
                    model: {
                      value: _vm.register_data.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.register_data, "name", $$v)
                      },
                      expression: "register_data.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("email_label") + "*" } },
                [
                  _c("b-input", {
                    attrs: { type: "email", state: _vm.register_errors.email },
                    model: {
                      value: _vm.register_data.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.register_data, "email", $$v)
                      },
                      expression: "register_data.email",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(" Please enter a valid email address "),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("organisation_label") } },
                [
                  _c("b-input", {
                    attrs: { type: "text", state: _vm.register_errors.company },
                    model: {
                      value: _vm.register_data.company,
                      callback: function ($$v) {
                        _vm.$set(_vm.register_data, "company", $$v)
                      },
                      expression: "register_data.company",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("phone_number_label") + "*" } },
                [
                  _c("phone-input", {
                    model: {
                      value: _vm.register_data.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.register_data, "phone", $$v)
                      },
                      expression: "register_data.phone",
                    },
                  }),
                  _c("b-form-text", [
                    _vm._v("In international E.164 format. E.g. +12025550101"),
                  ]),
                  _c("b-form-invalid-feedback", [
                    _vm._v(
                      " Please enter a phone number in international format, starting with + "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: "User Type" } },
                [
                  _c("b-form-radio-group", {
                    attrs: { options: _vm.user_types },
                    model: {
                      value: _vm.register_data.accessLevel,
                      callback: function ($$v) {
                        _vm.$set(_vm.register_data, "accessLevel", $$v)
                      },
                      expression: "register_data.accessLevel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mb-2",
                  attrs: { switch: "" },
                  model: {
                    value: _vm.register_data.sendEmail,
                    callback: function ($$v) {
                      _vm.$set(_vm.register_data, "sendEmail", $$v)
                    },
                    expression: "register_data.sendEmail",
                  },
                },
                [_vm._v(" Send welcome email with password link ")]
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("password_label") + "*" } },
                [
                  _c("b-input", {
                    attrs: {
                      type: "password",
                      state: _vm.register_errors.password,
                      disabled: _vm.register_data.sendEmail,
                    },
                    model: {
                      value: _vm.register_data.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.register_data, "password", $$v)
                      },
                      expression: "register_data.password",
                    },
                  }),
                  _c("b-form-text", [
                    _vm._v(
                      " Password must contain at least one uppercase, one lowercase, and one numerical character "
                    ),
                  ]),
                  _c("b-form-invalid-feedback", [
                    _vm._v(" Password does not meet requirements "),
                  ]),
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { label: _vm.$t("password_conf_label") + "*" } },
                [
                  _c("b-input", {
                    attrs: {
                      type: "password",
                      state: _vm.register_errors.passwordConf,
                      disabled: _vm.register_data.sendEmail,
                    },
                    model: {
                      value: _vm.register_data.passwordConf,
                      callback: function ($$v) {
                        _vm.$set(_vm.register_data, "passwordConf", $$v)
                      },
                      expression: "register_data.passwordConf",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v("Passwords do not match"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }