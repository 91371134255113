var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vue-tel-input",
    _vm._g(
      _vm._b(
        { ref: "input", on: { validate: _vm.onValidate } },
        "vue-tel-input",
        this.attrs,
        false
      ),
      this.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }