<script>
import formVal from '@/plugins/form-validator';
import OemApiTable from '@/components/OemApiTable.vue';
import PhoneInput from '@/components/PhoneInput.vue';

export default {
  name: 'OEMUserPage',
  components: {
    OemApiTable,
    PhoneInput,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'company',
          label: 'Company',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'accessLevel',
          label: 'Access Level',
        },
        {
          key: 'phone',
          label: 'Phone',
        },
        {
          key: 'id',
          label: '',
        },
      ],
      selected_user: null,
      user: {
        loading: true,
        name: '',
        company: '',
        email: '',
        emailVerified: false,
        password: '',
        password_valid: null,
        type: null,
      },
      user_types: [
        { text: 'Normal User', value: 'USER' },
        { text: 'OEM Admin', value: 'OEM' },
      ],
      register_data: {
        status: null,
        status_variant: 'danger',
        name: '',
        email: '',
        company: '',
        phone: '',
        accessLevel: 'USER',
        sendEmail: true,
        password: '',
        passwordConf: '',
        tAndC: true,
      },
      register_errors: {
        name: null,
        email: null,
        company: null,
        phone: null,
        password: null,
        passwordConf: null,
      },
    };
  },
  methods: {
    /**
     * Called when an edit button is pressed. Will pull that user's
     *   info from the server and open the edit modal
     */
    editUser() {
      this.$router.push(`/oem/user/${this.selected_user.id}`);
    },
    /**
     * Register a new user on the system
     */
    register(evt) {
      evt.preventDefault(); // Suppress native form submission
      // Perform some form validation
      const err = formVal.validate(this.register_data, formVal.constraints.user.register);
      if (this.register_data.sendEmail) {
        delete err.password;
        delete err.passwordConf;
      }
      if (err && Object.keys(err).length !== 0) {
        Object.keys(this.register_errors).forEach((key) => {
          if (key in err) {
            this.register_errors[key] = false;
          } else {
            this.register_errors[key] = true;
          }
        });
      } else {
        // Reset all errors
        Object.keys(this.register_errors).forEach((key) => {
          this.register_errors[key] = true;
        });
        this.$store.dispatch('admin/register', this.register_data)
          .then(() => {
            this.register_data.status = 'Registration Successful! You will get an email once your account is approved.';
            this.register_data.status_variant = 'success';
            this.$bvModal.hide('admin-add-user');
            this.$refs.table.refresh();

            // Reset Add User Form
            this.register_data.status = null;
            this.register_data.status_variant = 'danger';
            this.register_data.name = '';
            this.register_data.email = '';
            this.register_data.company = '';
            this.register_data.phone = '';
            this.register_data.accessLevel = 'USER';
            this.register_data.sendEmail = true;
            this.register_data.password = '';
            this.register_data.passwordConf = '';
            this.register_errors.name = null;
            this.register_errors.email = null;
            this.register_errors.company = null;
            this.register_errors.phone = null;
            this.register_errors.password = null;
            this.register_errors.passwordConf = null;
          })
          .catch((regErr) => {
            if (regErr.message === 'UserAlreadyExists') {
              this.register_data.status = 'An account with this email already exists';
              this.register_data.status_variant = 'danger';
            }
          });
      }
    },
  },
};
</script>
<template>
  <div>

    <!-- Normally Visible Stuff -->
    <b-container>
      <b-row>
        <b-col cols=12>
          <b-card title='User Management' class='mb-2'>
            <b-container>
              <b-row>
                <!-- Table -->
                <oem-api-table action='admin/getUsers'
                               :fields='fields'
                               addModal='admin-add-user'
                               v-model='selected_user'
                               v-on:input='editUser'
                               ref='table'>
                </oem-api-table>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- User Add Dialog -->
    <b-modal id='admin-add-user' title='New User' @ok='register'>
      <b-alert v-if='register_data.status'
                v-bind:variant='register_data.status_variant'
                show >
        {{ register_data.status }}
      </b-alert>
      <b-form ref='register_form' >
        <b-form-group
          :label="$t('name_label') + '*'" >
          <b-input type='text'
                  v-model='register_data.name'
                  v-bind:state='register_errors.name' />
        </b-form-group>
        <b-form-group
          :label="$t('email_label') + '*'">
          <b-input type='email'
                  v-model='register_data.email'
                  v-bind:state='register_errors.email' />
        <b-form-invalid-feedback>
          Please enter a valid email address
        </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          :label="$t('organisation_label')" >
          <b-input type='text'
                  v-model='register_data.company'
                  v-bind:state='register_errors.company' />
        </b-form-group>
        <b-form-group
          :label="$t('phone_number_label') + '*'" >
          <phone-input v-model='register_data.phone' />
          <b-form-text>In international E.164 format. E.g. +12025550101</b-form-text>
          <b-form-invalid-feedback>
            Please enter a phone number in international format, starting with +
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label='User Type'>
          <b-form-radio-group
            v-model="register_data.accessLevel"
            :options="user_types"></b-form-radio-group>
        </b-form-group>
        <b-form-checkbox class='mb-2' v-model='register_data.sendEmail' switch>
          Send welcome email with password link
        </b-form-checkbox>
        <b-form-group
          :label="$t('password_label') + '*'" >
          <b-input type='password'
                  v-model='register_data.password'
                  v-bind:state='register_errors.password'
                  v-bind:disabled='register_data.sendEmail' />
          <b-form-text>
            Password must contain at least one uppercase, one lowercase,
            and one numerical character
          </b-form-text>
          <b-form-invalid-feedback>
            Password does not meet requirements
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          :label="$t('password_conf_label') + '*'" >
          <b-input type='password'
                  v-model='register_data.passwordConf'
                  v-bind:state='register_errors.passwordConf'
                  v-bind:disabled='register_data.sendEmail' />
        <b-form-invalid-feedback>Passwords do not match</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>

  </div>
</template>
